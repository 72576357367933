@font-face {
    font-family: "Noto Sans Thai";
    src: url("../font/NotoSansThai/NotoSansThai-Regular.woff2") format("woff2"),
    url("../font/NotoSansThai/NotoSansThai-Regular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Thai";
    src: url("../font/NotoSansThai/NotoSansThai-SemiBold.woff2") format("woff2"),
    url("../font/NotoSansThai/NotoSansThai-SemiBold.woff") format("woff");
    font-style: auto;
    font-weight: bold;
    font-display: swap;
}

/* --------------------------- */
/*    RESET                    */
/* --------------------------- */


* {
    font-family: "Noto Sans Thai", sans-serif !important;
}

body {
    background-color: white;
    font-size: 16px;
}

html {
    scroll-behavior: smooth;
}

/* --------------------------- */
/*    Utility classes          */
/* --------------------------- */

.loading-view {
    top: 0 !important;
    height: 100vh;
    margin: 0;
    max-width: 100%;
    z-index: 20;
}

.editor-view {
    top: 0 !important;
    height: 100vh;
    margin: 0;
    max-width: 100%;
    z-index: 10;
}

.loading-view,
.editor-view > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}

.loading-view,
.editor-view > .ant-modal-content > .ant-modal-body {
    padding: 0;
}

.my-drawer
> .ant-drawer-content-wrapper
> .ant-drawer-content
> .ant-drawer-wrapper-body
> .ant-drawer-header {
    padding: 0;
}

.App {
    text-align: center;
    width: 95%;
    margin: 15px auto;
}

.container-drag {
    text-align: center;
}

.wip {
    position: absolute;
    width: 150px;
    height: 100vh;
    left: 0;
    top: 10px;
    background-color: #eeeeee;
    border-right: 1px dotted;
}

.header {
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: #476268;
    width: 100%;
}

.task-header {
    display: inline-block;
    background-color: skyblue;
    width: 100%;
}

.droppable {
    position: absolute;
    width: 150px;
    height: 100vh;
    right: 0;
    top: 10px;
    background-color: #9e9e9e;
    border-left: 1px dotted;
}

.draggable {
    width: 100px;
    height: 100px;
    background-color: yellow;
    margin: 5px auto;
    line-height: 5em;
}


/* --------------------------- */
/*    Modify AntDesign         */
/* --------------------------- */

.ant-layout {
    background: white;
}

.ant-menu-submenu-popup > .ant-menu {
    font-size: 22px;
    color: #000b8c;
}

.ant-modal-content {
    border-radius: 16px;
}  

.ant-modal-confirm-body > .anticon {
    margin-top: 8px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 24px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 20px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
    width: 140px;
    font-size: 16px;
    background-color: #000b8c;
}

.ant-drawer-body {
    padding: 0;
}

.ant-select-item {
    color: #000b8c;
    font-size: 22px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #000b8c;
    background-color: white;
}

.ant-popover-inner-content, .ant-popover-inner {
  padding: 0px;
  border-radius: 16px;
  box-shadow: none;
}

@page {
    size: portrait;
    width: 210mm;
    height: 297mm;
    margin: 0 0 10mm;

    background-color: white;
}
